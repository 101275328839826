import React from "react";

// Customizable Area Start
import { Typography, Button } from "@mui/material";
import { image1, image2, image3, customerIcon, bakerIcon } from "./assets";
// Customizable Area End

import LoginController, {
    Props,
} from "./LoginController";

export default class RolesLogin extends LoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <div style={styles.main}>
                <div style={styles.wrapper}>
                    <img src={require("../assets/banner.png")} style={styles.banner} />
                    <div style={{
                        position: "absolute",
                        content: '',
                        left: 0,
                        top: 0,
                        right: 0,
                        background: "linear-gradient(to right, rgba(0,0,0,0) 94%, rgb(0, 0, 0) 100%)",
                        bottom: 0,
                        zIndex: 1
                    }}></div>
                </div>
                <div style={styles.body}>
                    <div style={{marginTop: '20px', alignItems: 'center', display: 'flex', flexDirection: "column" as const, marginBottom: '24px' }}>
                        <img src={require("../../../components/src/logo.svg")}  width={71} height={50} />
                        <Typography variant="h5" style={{ fontWeight: "700", color: "#C2B30A", marginTop: '8px',  textTransform: "uppercase",  letterSpacing: "3px" }}>
                            AMATEUR BAKER
                        </Typography>
                        <Typography variant="caption" style={{ fontWeight: "200", color: "#C2B30A", marginTop: '4px', textTransform: "uppercase",letterSpacing: "3px" }}>
                            from our oven to your table
                        </Typography>
                    </div>

                    <div style={{ width: "40%" }}>
                        <div style={styles.buttonsWrapper}>
                            <Typography variant="h4" style={{ textTransform: "none", fontWeight: "700", marginTop: "60px", color: "#C2B30A", letterSpacing: "3px", textAlign: "center" }}>
                                Sign up
                            </Typography>
                            <Button
                                variant="contained"
                                fullWidth
                                data-test-id={"btnBaker"}
                                size="large"
                                style={styles.button}
                                onClick={this.goToSignup}
                            >
                                <img src={bakerIcon} />
                                <span style={{ textTransform: "none", marginLeft: "30px"}}> I'm a Baker</span>
                            </Button>
                        </div>
                        <div
                            style={styles.buttonsWrapper}
                        >
                            <Button
                                variant="contained"
                                fullWidth
                                data-test-id={"btnCustomer"}
                                size="large"
                                style={styles.signupbutton}
                                onClick={this.goToUserSignup}
                            >
                                <img src={customerIcon} />
                                <span style={{textTransform: "none", marginLeft: "30px" }}>
                                    I'm Customer
                                </span>
                            </Button>
                        </div>
                    </div>
                   
                    <div
                        style={styles.arrowWrapper}
                    >
                        <img src={require('../assets/back_arrow.png')} style={styles.backArrow}/>
                        <Typography onClick={this.goBackToInitial} style={styles.noAccountLabel} >
                            Back
                        </Typography>
                    </div>
                </div>
                <div style={styles.image1}>
                    <img src={image1} alt="image1"/>
                </div>
                <div style={styles.image2}>
                    <img src={image2} alt="image2"/>
                </div>
                <div style={styles.image3}>
                    <img src={image3} alt="image3"/>
                </div>

            </div>
            // Customizable Area End
        );
    }

}
// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {
    main: {
        alignItems: "center",
        display: "flex",
        backgroundColor: "#000000",
        justifyContent: "center",
        fontSize: 25,
    },
    wrapper: {
        position: "relative",
        flex: 1,
    },
    banner: {
        width: "100%",
        height: "100vh"
    },
    gradient: {
        height: '100%',
        width: '100%',
    },
    body: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        flexDirection: "column" as const,
        padding: "10px",
        justifyContent: "center",
        textTransform: "capitalize",
        width: "40%",
    },
    buttonsWrapper: {
        paddingTop: "0px",
        flex: 1,
        width: "100%",
        paddingBottom: "0px",
    },
    image1: {
        position: "absolute",
        top: 0,
        left: "50%"
    },
    image2: {
        position: "absolute",
        right: 0,
        top: 0
    },
    image3: {
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 0
    },
    logo: {
        width: 80,
        height: 80,
        marginBottom: "25px",
        marginTop: "50px",
    },
    arrowWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px"
    },
    button: {
        color: "#ffffff",
        backgroundColor: "#C2B30A",
        fontWeight: "700",
        padding: "15px",
        borderRadius: "10px",
        marginBottom: "20px",
        marginTop:"30px"
    },
    backArrow: {
        width: 30,
        height: 30,
    },
    noAccountLabel: {
        color: "#ffffff",
        fontSize: 16,
        fontWeight: "700",
        cursor: "pointer",
    },
    notHaveAccount: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50px",
        color: "#FFFFFF",
    },
    signupbutton: {
        backgroundColor: "transparent",
        border: "1px solid #C2B30A",
        padding: "15px",
        color: "#C2B30A",
        borderRadius: "10px",
        fontWeight: "700",
    },
    forgotPassword: {
        fontSize: 13,
        fontWeight: "700",
        textAlign: "left" as const,
        lineHeight: 5,
        marginLeft: 5,
        color: "#FFFFFF",
    },
};
// Customizable Area End