import React from "react";
// Customizable Area Start
import { Box, Button, Typography } from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import SwitchSelector from "react-switch-selector";
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CatalogueController, { Props } from "./CatalogueController.web";
// Customizable Area End
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <Box sx={{ width: '100%', height: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
          <Typography variant="h6" sx={{
            fontSize: '28px',
            fontWeight: 700,
          }}>
            <span style={{ color: "#C2B30A" }}>
              Bakers's
            </span>
            <span style={{ color: "#ffffff", marginLeft: "3px" }}>
              specialties
            </span>
          </Typography>
          <Button
            onClick={() => this.props.history.push('/catalogue/catalogue-create')}
            data-test-id="opnBtn"
            sx={{
              width: '343px',
              height: '52px',
              padding: '14px 24px',
              borderRadius: '8px',
              color: '#ffffff',
              backgroundColor: '#C2B30A',
              '&:hover': {
                backgroundColor: '#a59a00',
              },
              fontSize: '18px',
              fontWeight: 700,
              textTransform: 'none'
            }}
          >Create New Specialty
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", width: "60%", gap: 3 }}>
          <OutlinedInput
            fullWidth
            id="outlined-adornment-email"
            type={'text'}
            placeholder={"Search"}
            data-test-id='emailInput'
            size="small"
            style={webStyle.inputLabel}
            inputProps={{ style: { fontSize: '12px'} }}
            endAdornment={<SearchIcon fontSize="small" sx={{ color: "#ffffff", paddingRight:2 }}
            />}
        />

          <FormControl sx={{ mx: 1, minWidth: 120 }} size="medium">
            <Select
              sx={webStyle.select}
              labelId="demo-select-small-label"
              data-test-id="filter-btn"
              value={this.state.token}
              label=""
              placeholder="Sort By"
              displayEmpty
              size="small"
              onChange={(e) => this.handleChange(e)}
              startAdornment={<SortIcon sx={{ color: "#ffffff", mr: 1 }}/>}
              IconComponent={() => <KeyboardArrowDownIcon sx={{ color: "#ffffff", mr: 1 }} />}
            >
              <MenuItem value="">
                Sort by
              </MenuItem>
              <MenuItem value={'one'}>One</MenuItem>
              <MenuItem value={'two'}>Two</MenuItem>
            </Select>
          </FormControl>
          <FilterAltOutlinedIcon sx={webStyle.filterIcon} />
        </Box>

        <Divider sx={{ borderColor: "#57534E", marginY: 2 }} />

        <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center", gap: 3 }}>
          <img
            style={{ width: 151, borderRadius: '4px' }}
            src={require("../assets/dishImage.jpeg")}
            alt="Live from space album cover"
          />
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Box style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <Typography
                variant="h6" sx={{
                  fontSize: '18px',
                  fontWeight: 700,
                }}>
                Chocolate Brownies
              </Typography>
              <div
                style={{
                  height: 30,
                  width: 220
                }}
              >
                <SwitchSelector
                  backgroundColor="#57534E"
                  fontColor="#f9f9f9"
                  initialSelectedIndex={0}
                  onChange={() => { }}
                  options={[
                    {
                      label: <Typography
                        variant="h6" sx={{
                          fontSize: '12px',
                          fontWeight: 700,
                        }}>
                        Unavailable
                      </Typography>,
                      value: true
                    },
                    {
                      label: <Typography
                        variant="h6" sx={{
                          fontSize: '12px',
                          fontWeight: 700,
                        }}>
                        Available
                      </Typography>,
                      value: 20
                    }
                  ]}
                  selectedBackgroundColor="#C2B30A"
                  selectedFontColor="#ffffff"
                  selectionIndicatorMargin={3}
                />
              </div>
            </Box>
            <Box style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Typography sx={{
                fontFamily: "Cerebri Sans"
              }} variant="subtitle1" color="text.secondary">
                <Chip sx={{ color: "#ffffff", backgroundColor: "#57534E", mr: 1 }} label="Category" />
                <Chip sx={{ color: "#ffffff", backgroundColor: "#57534E" }} label="Allergen" />
              </Typography>
              <ChevronRightIcon sx={{ color: "#C2B30A" }} />
            </Box>
            <Typography sx={{
              mt: 1,
              fontSize: '18px',
              fontWeight: 700,
            }}
              variant="h6">$123.00
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ borderColor: "#57534E", marginY: 2 }} />
      </Box>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  inputLabel: {
    color: "#ffffff",
    borderRadius: 4,
    backgroundColor: "#57534E",
    padding:"2px 4px"
  },
  select: {
    color: "#ffffff",
    fontSize: "12px",
    fontWeight: 400,
    backgroundColor: "#57534E",
    paddingTop: "2px",
    paddingBottom: "2px",
    borderRadius: 1,
  },
  filterIcon: {
    width: '1rem',
    height: '1rem',
    fontSize: '1.2rem',
    backgroundColor: '#57534E',
    padding: '11px 11px',
    borderRadius: '4px',
    color: '#ffffff',
    marginRight:1
  },
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    //marginBottom: 15,
    height: 150,
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
};
// Customizable Area End
