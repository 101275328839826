import React from "react";
import ChangePasswordController, {
    Props,
} from "./ChangePasswordController.web";
import OutlinedInput from '@mui/material/OutlinedInput';

import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, InputLabel, Snackbar, Alert } from "@mui/material";
import { valid, invalid} from "./assets";
import PasswordStrengthIndicator from "./../../../components/src/PasswordStrengthIndicator";

export default class ChangePassword extends ChangePasswordController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 5
            }}>
                <Snackbar
                    open={this.state.showToast}
                    autoHideDuration={6000}
                    onClose={this.handleCloseToast}
                >
                    <Alert onClose={this.handleCloseToast} severity={'success'}>
                        {this.state.toastMessage}
                    </Alert>
                </Snackbar>
                <Formik
                    initialValues={{ currentPassword: "", newPassword: "", confirmPassword: "" }}
                    validationSchema={
                        Yup.object().shape({
                            currentPassword: Yup.string().required("Current Password is required"),
                            newPassword: Yup.string().required("New Password is required"),
                            confirmPassword: Yup.string()
                                .required("Confirm Password is required")
                                .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
                        })
                    }
                    validateOnMount={true}
                    validateOnChange={true}
                    onSubmit={(values) => {
                        this.changePassword(values);
                    }}
                    data-testid="passForm"
                >
                    {({
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        values
                    }) => (
                        <>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'flex-start'}
                                justifyContent={'center'}
                                width={'65%'}
                            >
                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Current Password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    onChange={handleChange("currentPassword")}
                                    type={'text'}
                                    placeholder={"Current Password"}
                                    value={values.currentPassword}
                                    data-test-id='currentPass'
                                />
                                {errors.currentPassword && touched.currentPassword ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.currentPassword}
                                    </span>
                                ) : null}
                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>New Password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"New Password"}
                                    value={values.newPassword}
                                    onChange={handleChange("newPassword")}
                                    data-test-id='newPass'
                                />
                                {errors.newPassword && touched.newPassword ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.newPassword}
                                    </span>
                                ) : null}
                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Confirm Password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"Confirm Password"}
                                    value={values.confirmPassword}
                                    onChange={handleChange("confirmPassword")}
                                    data-test-id='confirmPass'
                                />
                                {errors.confirmPassword && touched.confirmPassword ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.confirmPassword}
                                    </span>
                                ) : null}

                                <PasswordStrengthIndicator invalid={invalid} valid={valid} password={values?.newPassword} />
                               
                            </Box>
                            <Button
                                data-test-id={"changePassBtn"}
                                style={webStyles.button}
                                color="secondary"
                                variant="outlined"
                                onClick={() => handleSubmit()}
                            >
                                Change Password
                            </Button>
                        </>
                    )}
                </Formik>
            </Box>
        )
    }
}

const webStyles = {
    inputStyle: {
        height: "100px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
    },
    buttonStyle: {
        width: "100%",
        marginTop: "40px",
        height: "45px",
        backgroundColor: "rgb(98, 0, 238)",
    },
    icon: {
        height: "18px",
        width: "18px",
        margin: "5px 7px 5px 2px",
    },
    iconValid: {
        height: "20px",
        width: "22px",
        margin: "5px 5px 5px 0px",
    },

    errorStyle: {
        textAlign: "center" as const,
        color: "red",
        fontSize: 12
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        // textTransform: 'none',
        padding: "15px",
        borderRadius: 8,
    },
    mainWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        backgroundColor: "#ffff",
        width: "50%",
        fontFamily: "Roboto, sans-serif"
    },
    inputLabel: {
        marginBottom: "6px",
        color: "#A8A29E",
        borderRadius: 8,
        backgroundColor: "#57534E",
        paddingBottom: "3px",
    },
    titleText: {
        fontSize: 30,
        paddingVertical: 10,
        fontWeight: "600",
    },
    bodyText: {
        fontSize: 20,
        paddingVertical: 15,
        fontWeight: "400",
    },

};