import React from "react";

import UserProfileController, {
    Props,
} from "./UserProfileController.web";

import OutlinedInput from '@mui/material/OutlinedInput';

import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, Typography, InputLabel } from "@mui/material";

export default class UserProfile extends UserProfileController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px", marginTop: 5 }}>
                <div>
                    <label htmlFor="myfileid">
                        <img 
                        src={ this.state.profile_pic || require("../assets/user.png")} 
                        style={webStyles.profileImg} width="50" height="50" />
                    </label>
                    <input data-test-id="upload-btn" onChange={(e) => this.updateProfilePic(e.target.files)} type="file" name="something" id="myfileid" style={{ display: "none" }} />
                </div>
                <div style={{
                    display: 'flex', flexDirection: "column",
                    justifyContent: "space-evenly", alignItems: "center"
                }}>
                    <Typography variant='subtitle2' data-test-id="name" style={{
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '17.78px',
                    }}>{this.state.user?.firstName} {this.state.user?.lastName}</Typography>
                    <Typography data-test-id="email" variant='body2'>{this.state.user?.email}</Typography>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={
                    this.state.user || { firstName: "", lastName: "", phone: "", email: "", address: "", state: "", zipcode: "", instaLink: "", fbLink: "", }
                    }
                    validationSchema={Yup.object().shape({
                        firstName: Yup.string().required("first name is required"),
                        lastName: Yup.string().required("last name is required"),
                        phone: Yup.string()
                            .matches(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/, {
                                message: "Invalid phone number",
                                excludeEmptyString: false,
                            }),
                        email: Yup.string().email().required("Email is required"),
                        address: Yup.string().required("Address is required"),
                        state: Yup.string().required("State is required"),
                        zipcode: Yup.string().required("Zipcode is required"),
                    })}
                    validateOnMount={true}
                    validateOnChange={true}
                    onSubmit={(values) => {
                        this.saveProfile(values);
                    }}
                    data-testid="profileForm"
                >
                    {({
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        values
                    }) => (
                        <>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'flex-start'}
                                justifyContent={'center'}
                                width={'65%'}
                            >
                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px", marginTop: '5px' }}>First Name</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"First Name"}
                                    value={values.firstName}
                                    onChange={handleChange("firstName")}
                                    data-test-id='firstNameInput'
                                />
                                {errors.firstName && touched.firstName ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.firstName}
                                    </span>
                                ) : null}

                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Last Name</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"Last Name"}
                                    value={values.lastName}
                                    onChange={handleChange("lastName")}
                                    data-test-id='lastNameInput'
                                />
                                {errors.lastName && touched.lastName ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.lastName}
                                    </span>
                                ) : null}

                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Phone Number</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"Phone Number"}
                                    value={values.phone}
                                    onChange={handleChange("phone")}
                                    data-test-id='phoneInput'
                                />
                                {errors.phone && touched.phone ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.phone}
                                    </span>
                                ) : null}

                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Email</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"Email"}
                                    value={values.email}
                                    onChange={handleChange("email")}
                                    data-test-id='emailInput'
                                />
                                {errors.email && touched.email ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.email}
                                    </span>
                                ) : null}

                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Address</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"Address"}
                                    value={values.address}
                                    onChange={handleChange("address")}
                                    data-test-id='addressInput'
                                />
                                {errors.address && touched.address ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.address}
                                    </span>
                                ) : null}


                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>State</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"State"}
                                    value={values.state}
                                    onChange={handleChange("state")}
                                    data-test-id='stateInput'
                                />
                                {errors.state && touched.state ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.state}
                                    </span>
                                ) : null}

                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Zip Code</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"zipcode"}
                                    value={values.zipcode}
                                    onChange={handleChange("zipcode")}
                                    data-test-id='zipcodeInput'
                                />
                                {errors.zipcode && touched.zipcode ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.zipcode}
                                    </span>
                                ) : null}

                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Insta Link</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"Insta Link"}
                                    value={values.instaLink}
                                    onChange={handleChange("instaLink")}
                                    data-test-id='instaLinkInput'
                                />
                                {errors.instaLink && touched.instaLink ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.instaLink}
                                    </span>
                                ) : null}

                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Facebook Link (optional)</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"Facebool link"}
                                    value={values.fbLink}
                                    onChange={handleChange("fbLink")}
                                    data-test-id='fbLinkInput'
                                />
                            </Box>

                            <Button
                                data-test-id={"submitBtn"}
                                sx={{
                                    backgroundColor: "#C2B30A",
                                    color: "#ffffff",
                                    padding: "15px",
                                    borderRadius: "8px",
                                    fontWeight: 700,
                                    textTransform: 'none'
                                }}
                                color="secondary"
                                variant="outlined"
                                onClick={() => handleSubmit()}
                            // disabled={!this.state.cancelEnabled}
                            >
                                Save Changes
                            </Button>
                        </>
                    )}
                </Formik>
            </Box>
        )
    }
}

const webStyles = {
    inputLabel: {
        color: "#A8A29E",
        borderRadius: '8px',
        backgroundColor: "#57534E",
        marginBottom: "8px"
    },
    profileImg: {
        width: '50px', 
        height: '50px', 
        borderRadius: '50%', 
    },
    errorStyle: {
        color: "red",
        textAlign: "center" as const,
        fontSize: 12,
        marginBottom: 2
    },
    mainWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        backgroundColor: "#ffff",
        width: "50%",
        fontFamily: "Roboto, sans-serif"
    },
    titleText: {
        fontSize: 30,
        paddingVertical: 10,
        fontWeight: "600",


    },
    bodyText: {
        fontSize: 20,
        paddingVertical: 15,
        fontWeight: "400",
    },
    inputStyle: {
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        backgroundColor: "rgb(98, 0, 238)",
    },
};