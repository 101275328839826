import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
}

interface S {
    profile_pic: any,
    user: any
}

interface SS {
    id: any;
}

export default class UserProfileController extends BlockComponent<
    Props,
    S,
    SS
> {
    putUserProfilePicRequestId: string = "";
    putUserProfileRequestId: string = "";

    getUserProfileRequestId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            profile_pic: "",
            user: {}
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

            if (apiRequestCallId == this.putUserProfilePicRequestId) {
                if (responseJson.data) {
                    this.setState({
                        profile_pic: responseJson?.data?.attributes?.profile_pic
                    })
                    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                }
            } else if (apiRequestCallId == this.putUserProfileRequestId) {
                this.getProfileDetails();
            } else if (apiRequestCallId == this.getUserProfileRequestId) {
                this.setState({
                    user: this.transformData({
                        ...responseJson.data.attributes,
                    })
                });
            }
        }

    }

    async componentDidMount() {
        super.componentDidMount();

        const userString = localStorage.getItem('user');

        if (userString) {
            let user: any = JSON.parse(userString);

            if (user && user.data) {
                this.setState({
                    user: this.transformData(user.data.attributes),
                    profile_pic: user.data.attributes.profile_pic
                });
            }
        }
    }

    transformData = (data: any) => {

        const {
            first_name,
            last_name,
            phone_number,
            email,
            addresses,
            state,
            zipcode
        } = data;

        const address = addresses?.data?.length > 0 ? addresses.data[0].attributes.address : '';

        return {
            firstName: first_name || '',
            lastName: last_name || '',
            phone: phone_number || '',
            email: email || '',
            address: address || '',
            state: state || '',
            zipcode: zipcode || '',
            instaLink: '',
            fbLink: '',
        };
    };

    saveProfile = (values: any) => {
        const token: any = localStorage.getItem('authToken');

        let httpBody = {
            data: {
                attributes: {
                    first_name: values.firstName || '',
                    last_name: values.lastName || '',
                    phone_number: values.phone || '',
                    email: values.email || '',
                    insta_link: values.instaLink || '',
                    facebook_link: values.fbLink || '',
                    address: {
                        address: values.address || '',
                    },
                    state: values.state || '',
                    zipcode: values.zipcode || ''
                }
            }
        }

        const message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.putUserProfileRequestId = message.messageId;

        message.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateProfile}`
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": configJSON.validationApiContentType,
                token: token,
            })
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypePut
        );

        runEngine.sendMessage(message.id, message);

    }

    updateProfilePic(files: any) {

        const token: any = localStorage.getItem('authToken');
        let formData = new FormData()
        formData.append("account[profile_pic]", files[0]);

        let httpBody = formData;

        const header = {
            "Accept": "*/*",
            "token": token
        }

        const message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.putUserProfilePicRequestId = message.messageId;

        message.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateProfilePic}`
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            httpBody
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypePut
        );

        runEngine.sendMessage(message.id, message);
    }

    getProfileDetails() {
        const token: any = localStorage.getItem('authToken');

        const message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getUserProfileRequestId = message.messageId;

        message.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getProfile}`
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": configJSON.validationApiContentType,
                token: token,
            })
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeGet
        );

        runEngine.sendMessage(message.id, message);
    }
}
