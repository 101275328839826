import React from "react";
import ChangeEmailController, {
    Props,
} from "./ChangeEmailController.web";
import OutlinedInput from '@mui/material/OutlinedInput';

import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, Typography, InputLabel } from "@mui/material";

export default class ChangeEmail extends ChangeEmailController {
    constructor(props: Props) {
        super(props);
    }

    render() {

        return (
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px", marginTop: 5 }}>
                {this.state.step == "one" && <Formik
                    initialValues={{ email: "test@example.com" }}
                    // Complete: make sure props have email field in it and use it to set initial value
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email().required("Email is required"),
                    })}
                    validateOnMount={true}
                    validateOnChange={true}
                    onSubmit={(values) => {
                        this.goToNextStep('two');
                    }}
                    data-testid="emailForm"
                >
                    {({
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        values
                    }) => (
                        <>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'flex-start'}
                                justifyContent={'center'}
                                width={'65%'}
                            >
                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Current Email</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"New Email"}
                                    value={values.email}
                                    onChange={handleChange("email")}
                                    data-test-id='emailInput'
                                />
                                {/* Complete: This should not be required as step one has account creation email */}
                                {errors.email && touched.email ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.email}
                                    </span>
                                ) : null}
                            </Box>
                            <Button
                                data-test-id={"changeEmailBtn"}
                                style={webStyles.button}
                                color="secondary"
                                variant="outlined"
                                onClick={() => handleSubmit()}
                            >
                                Change Email
                            </Button>
                        </>
                    )}
                </Formik>}

                {this.state.step == "two" && <Formik
                    initialValues={{ newEmail: "" }}
                    validationSchema={Yup.object().shape({
                        newEmail: Yup.string().email().required("New Email is required"),
                    })}
                    validateOnMount={true}
                    validateOnChange={true}
                    onSubmit={(values) => {
                        this.goToNextStep('three');
                    }}
                    data-testid="continueForm"
                >
                    {({
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        values
                    }) => (
                        <>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'flex-start'}
                                width={'65%'}
                            >
                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Enter your New Email</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    placeholder={"Type New Email"}
                                    id="outlined-email"
                                    type={'text'}
                                    value={values.newEmail}
                                    data-test-id='newemailInput'
                                    onChange={handleChange("newEmail")}
                                />
                                {errors.newEmail && touched.newEmail ? (
                                    <span data-testid="emailError" style={webStyles.errorStyle}>
                                        {errors.newEmail}
                                    </span>
                                ) : null}
                            </Box>
                            <Button
                                data-test-id={"continueButton"}
                                style={webStyles.button}
                                color="secondary"
                                variant="outlined"
                                onClick={() => handleSubmit()}
                            >
                                Continue
                            </Button>
                        </>
                    )}
                </Formik>}

                {this.state.step == "three" && <Formik
                    initialValues={{ otp1: '', otp2: '', otp3: '', otp4: '' }}
                    validationSchema={Yup.object().shape({
                        otp1: Yup.string().required(''),
                        otp2: Yup.string().required(''),
                        otp3: Yup.string().required(''),
                        otp4: Yup.string().required(''),
                    })
                    }
                    validateOnMount={true}
                    validateOnChange={true}
                    onSubmit={(values) => {
                    }}
                    data-testid="otpForm"
                >
                    {({
                        setFieldValue,
                        handleSubmit,
                        errors,
                        touched,
                        values
                    }) => (
                        <>
                            <Typography variant="subtitle1"
                                sx={{
                                    textAlign: "center",
                                }}> <span style={{ color: "#ffffff" }}>
                                    Please  &nbsp;
                                </span><span style={{ color: "#C2B30A", fontWeight: 700 }}>
                                    ENTER CODE
                                </span> <span style={{ color: "#ffffff" }}>
                                    we send you to your Email
                                </span>
                            </Typography>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                gap={3}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    gap={3}
                                >
                                    <OutlinedInput
                                        sx={{
                                            "MuiOutlinedInput-input": {
                                                textAlign: "center"
                                            },
                                            width: "50px", borderColor: errors.otp1 && touched.otp1 ? 'red' : "none"
                                        }}
                                        style={webStyles.inputLabel}
                                        id="outlined-otp1"
                                        type={'text'}
                                        value={values.otp1}
                                        onChange={(e) => this.handleOTPInput(e, 'otp1', setFieldValue)}
                                        placeholder={"-"}
                                        data-test-id='otp1'
                                    />
                                    <OutlinedInput
                                        sx={{
                                            "MuiOutlinedInput-input": {
                                                textAlign: "center"
                                            }, width: "50px", borderColor: errors.otp2 && touched.otp2 ? 'red' : "none"
                                        }}
                                        style={webStyles.inputLabel}
                                        id="outlined-otp2"
                                        type={'text'}
                                        value={values.otp2}
                                        onChange={(e) => this.handleOTPInput(e, 'otp2', setFieldValue)}
                                        placeholder={"-"}
                                        data-test-id='otp2'
                                    />
                                    <OutlinedInput
                                        sx={{
                                            width: "50px", "MuiOutlinedInput-input": {
                                                textAlign: "center"
                                            }, borderColor: errors.otp3 && touched.otp3 ? 'red' : "none"
                                        }}
                                        style={webStyles.inputLabel}
                                        id="outlined-otp3"
                                        type={'text'}
                                        inputProps={{ maxLength: 1 }}
                                        value={values.otp3}
                                        onChange={(e) => this.handleOTPInput(e, 'otp3', setFieldValue)}
                                        placeholder={"-"}
                                        data-test-id='otp3'
                                    />
                                    <OutlinedInput
                                        sx={{
                                            width: "50px", "MuiOutlinedInput-input": {
                                                textAlign: "center"
                                            }, borderColor: errors.otp4 && touched.otp4 ? 'red' : "none"
                                        }}
                                        style={webStyles.inputLabel}
                                        id="outlined-otp4"
                                        type={'text'}
                                        value={values.otp4}
                                        onChange={(e) => this.handleOTPInput(e, 'otp4', setFieldValue)}
                                        placeholder={"-"}
                                        data-test-id='otp4'
                                    />
                                </Box>
                                <Button
                                    fullWidth
                                    data-test-id={"verifyBtn"}
                                    style={webStyles.button}
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() => handleSubmit()}
                                >
                                    Verify
                                </Button>
                                <Button
                                    fullWidth
                                    data-test-id={"backBtn"}
                                    style={webStyles.button}
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() => this.goToNextStep('two')}
                                >
                                    Back
                                </Button>
                            </Box>
                        </>
                    )}
                </Formik>}
            </Box>
        )
    }
}

const webStyles = {
    inputLabel: {
        borderRadius: 8,
        color: "#A8A29E",
        backgroundColor: "#57534E",
        paddingBottom: "3px"
    },
    inputStyle: {
        width: "100%",
        justifyContent: "space-between",
        height: "100px",
        display: "flex",
        flexDirection: "column",
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        // textTransform: 'none',
        borderRadius: 8,
    },
    mainWrapper: {
        display: "flex",
        width: "50%",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        backgroundColor: "#ffff",
        fontFamily: "Roboto, sans-serif"
    },
    titleText: {
        fontSize: 30,
        paddingVertical: 10,
        fontWeight: "600",
    },
    errorStyle: {
        textAlign: "center" as const,
        color: "red",
        fontSize: 12
    },
    bodyText: {
        fontWeight: "400",
        fontSize: 20,
        paddingVertical: 15,
    },
    buttonStyle: {
        width: "100%",
        backgroundColor: "rgb(98, 0, 238)",
        height: "45px",
        marginTop: "40px",
    },
};