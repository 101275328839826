import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import queryString from 'query-string';
import { AlertColor } from "@mui/material";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  history: { push: any };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  data: Object,
  showPassword: boolean,
  userRole: string,
  showToast: boolean,
  toastMessage: string,
  toastSeverity: AlertColor;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: any = "";
  validationApiCallId: string = "";
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      data: {},
      showPassword: false,
      userRole: "",
      showToast: false,
      toastMessage: '',
      toastSeverity: 'success' as AlertColor,
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const values = queryString.parse(this.props.location.search);
    this.setState({
      userRole: values.role === "chef" ? "chef" : "customer",
    });
    const styleSheet = document.createElement("style");
    styleSheet.innerText = `
      input:-webkit-autofill,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #57534E inset !important;
        -webkit-text-fill-color: #A8A29E !important;
      }
      input:-moz-autofill {
        box-shadow: 0 0 0 30px #57534E inset !important;
        color: #A8A29E !important;
      }
    `;
    document.head.appendChild(styleSheet);
    // Customizable Area End
  }
  // Customizable Area End
  // Customizable Area Start
  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiEmailLoginCallId) { this.handleEmailLoginResponse(responseJson);}
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleEmailLoginResponse(responseJson: any) {
    if (responseJson?.meta?.token) {
      this.onLoginSuccess(responseJson);
    } else {
      this.onLoginFail(responseJson);
    }
  }

  onLoginSuccess(responseJson: any) {
    localStorage.setItem('user', JSON.stringify(responseJson.meta.account));
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    this.saveLoggedInUserData(responseJson);
    this.handleShowToast("login successful!", "success");
    this.props.history.push("/dashboard")
  }

  onLoginFail(responseJson: any) {
    const errorMessage = responseJson.errors?.[0]?.failed_login || "Something went wrong";
    this.handleShowToast(errorMessage, "error");
    this.parseApiErrorResponse(responseJson);
    this.sendLoginFailMessage();
  }
  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  doEmailLogIn(values: { email: string, password: string }): boolean {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: values.email,
      password: values.password,
    };

    const data = {
      type: "email_account",
      attributes: {
        ...attrs,
        user_type: this.state.userRole,
      },
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  goToLogin = () => {
    this.props.history.push("/login?role=chef")
  }
  goToRolesLogin = () => {
    this.props.history.push("/rolesLogin")
  }
  goToRolesSignup = () => {
    this.props.history.push("/rolesSignup")
  }
  goToUserLogin = () => {
    this.props.history.push("/login?role=customer");
  }
  goToUserSignup = () => {
    this.props.history.push("/userRegistration?role=customer");
  }
  goToSignup = () => {
    this.props.history.push("/userRegistration?role=chef");
  }
  goBackToInitial = () => {
    this.props.history.push("/");
  }

  handleShowToast = (message: string, severity: AlertColor) => {
    this.setState({ showToast: true, toastMessage: message, toastSeverity: severity });
  }

  handleCloseToast = () => {
    this.setState({ showToast: false });
  }
  handlecheckboxfilled = () => {
    let check2 = true;
    if (check2) {
     this.handleunchecked()
    }
  }
  handleunchecked = () => {
    let check1 = false
    if (!check1) {
     this.handleSubmits()
    }
  }
  handleSubmits = ()=>{
    let assign = true
    if(assign){
      return;
    }
  }
  // Customizable Area End
}
