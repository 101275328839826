import React from "react";

// Customizable Area Start
import {
    TextField,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    styled,
    Pagination, PaginationItem
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import { downArrow, filterIcon, arrowIcon, prevIcon, nextIcon } from "./assets";

const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
        color: "#FFFFFF",
        borderRadius: "20px",
        margin: "0 5px",
        minWidth: "40px",
        minHeight: "40px",
    },
    "& .MuiPaginationItem-ellipsis": {
        color: "#FFFFFF",
    },
    "& .Mui-selected": {
        backgroundColor: '#C2B30A !important',
        color: "white",
    },
    "& .MuiPaginationItem-icon": {
        color: "#FFFFFF",
    },
});
// Customizable Area End

import UserOrderHistoryController, {
    Props,
} from "./UserOrderHistoryController.web";

export default class UserOrderHistory extends UserOrderHistoryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <div style={styles.main}>
                <div style={{ padding: '20px', backgroundColor: '#292524', color: 'white', height: "100vh", display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', width: "50%" }}>
                        <TextField
                            data-test-id="searchbar"
                            variant="outlined"
                            placeholder="Search"
                            value={this.state.searchQuery}
                            onChange={this.handleSearchChange}
                            InputProps={{
                                style: { color: 'white', padding: "2px 50px" },
                                endAdornment: (
                                    <IconButton>
                                        <SearchIcon style={{ color: 'white' }} />
                                    </IconButton>
                                )
                            }}
                            style={{
                                backgroundColor: '#57534E',
                                borderRadius: '15px',
                                flex: 1,
                                marginRight: '10px',
                            }}
                        />
                        <IconButton style={{ padding: "15px", color: 'white', backgroundColor: '#57534E', borderRadius: '15px', marginRight: '10px' }}>
                            <SortIcon />
                            <Typography>Sort by</Typography>
                            <img src={downArrow} alt="down arrow" />
                        </IconButton>
                        <IconButton style={{ backgroundColor: '#57534E', color: 'white', borderRadius: '15px', padding: "20px", }}>
                            <img src={filterIcon} alt="filter icon" />
                        </IconButton>
                    </div>

                    <List style={{ flexGrow: 1, marginBottom: '20px', maxHeight: 'calc(100vh - 250px)', overflow: "hidden" }}>
                        {this.state.bakers.map((baker) => (
                            <ListItem data-test-id="listItems" onClick={() => this.gotoCartItems(baker.id)} key={baker.id} style={{ marginBottom: '10px', borderTop: '1px solid #57534E', padding: '10px 0', cursor: "pointer" }}>
                                <img
                                    style={{ borderRadius: "10px", width: "60px", height: "60px", marginRight: "20px" }}
                                    src={(baker.images && baker.images.length > 0) ? baker.images[0] : "https://via.placeholder.com/60"}
                                    alt={baker.bakerName}
                                />
                                <ListItemText
                                    primary={<Typography style={{ color: 'white', fontWeight: 'bold' }}>{baker.bakerName}</Typography>}
                                    secondary={<Typography style={{ color: '#a3a3a3' }}>Date: {baker.date} • Pick up: {baker.pickup}</Typography>}
                                />
                                <IconButton edge="end" style={{ color: '#FFDD00' }}>
                                    <img src={arrowIcon} alt="arrow icon" />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>

                    <div style={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>
                        <StyledPagination
                            data-test-id="pagination"
                            count={Math.ceil(this.state.bakers.length / this.state.itemsPerPage)}
                            page={this.state.page}
                            onChange={this.handlePaginationChange}
                            renderItem={(item) => (
                                <PaginationItem
                                    {...item}
                                    components={{
                                        previous: (props) => (
                                            <div data-test-id="prev" {...props} style={styles.btns}>
                                                <img style={{ marginRight: "10px" }} src={prevIcon} alt="prev" /> Prev
                                            </div>
                                        ),
                                        next: (props) => (
                                            <div data-test-id="prev" {...props} style={styles.btns}>
                                                Next <img src={nextIcon} alt="next" style={{ marginLeft: "10px" }} />
                                            </div>
                                        ),
                                    }}
                                />
                            )}
                            siblingCount={1}
                            boundaryCount={1}
                        />
                    </div>
                </div>
            </div>
            // Customizable Area End
        );
    }

}
// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {
    main: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
    },
    btns: {
        borderRadius: "25px",
        backgroundColor: '#57534E',
        color: "white",
        width: "74px",
        height: "38px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px 10px"
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700",
        marginBottom: "20px",
        borderRadius: "10px",
        marginTop: "30px"
    },
};
// Customizable Area End