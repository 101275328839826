import React from "react";

// Customizable Area Start
import { Typography, Button } from "@mui/material";
import { image1, image2, image3 } from "./assets";
// Customizable Area End

import LoginController, {
    Props,
} from "./LoginController";

export default class SignupLoginDesktop extends LoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start


    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <div  style={styles.main}>
                 <div style={styles.image1}>
                    <img src={image1} alt="image1"/>
                </div>
                <div style={styles.image2}>
                    <img src={image2} alt="image2"/>
                </div>
                <div style={styles.image3}>
                    <img src={image3} alt="image3"/>
                </div>
                <div  style={styles.wrapper}>
                    <img src={require("../assets/banner.png")}  style={styles.banner}  />
                    <div style={{
                        position: "absolute",
                        content: '',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 1,
                        background: "linear-gradient(to right, rgba(0,0,0,0) 94%, rgb(0, 0, 0) 100%)",
                    }}>

                    </div>
                </div>
                <div style={styles.body}>
                    <div style={{  flexDirection: "column" as const,display: 'flex', alignItems: 'center', marginBottom: '24px', marginTop: '20px' }}>
                        <img height={100} width={100}  src={require("../../../components/src/logo.svg")} />
                        <Typography variant="h5" style={{ fontWeight: "700", fontSize: "50px",color: "#C2B30A",  textTransform: "uppercase", marginTop: '8px', letterSpacing: "3px" }}>
                            AMATEUR BAKERS
                        </Typography>
                        <Typography variant="caption" style={{ color: "#C2B30A", fontSize: "20px",  fontWeight: "200", textTransform: "uppercase", marginTop: '4px', letterSpacing: "3px" }}>
                            from our oven to your table
                        </Typography>
                    </div>
                    <div style={{ width: "40%"  }}>
                        <div
                            style={styles.buttonsWrapper}
                        >
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                data-test-id={"btnLogin"}
                                onClick={this.goToRolesLogin}
                                style={styles.button}
                            >Log in
                            </Button>
                        </div>
                        <div
                            style={styles.buttonsWrapper}
                        >
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                data-test-id={"btnSignup"}
                                onClick={this.goToRolesSignup}
                                style={styles.signupbutton}
                            >Sign Up
                            </Button>
                        </div> 

                    </div>

                </div>

            </div>
            // Customizable Area End
        );
    }

}
// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {
   
    wrapper: {
        flex: 1,
        position: "relative",
    },
    image1: {
        position: "absolute",
        top: 0,
        left: "50%"
    },
    image2: {
        position: "absolute",
        right: 0,
        top: 0
    },
    image3: {
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 0
    },
    banner: {
        width: "100%", height: "100vh"
    },
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#000000",
        fontSize: 25,
    },
    
    gradient: {
        width: '100%',
        height: '100%',
    },
    
    
    buttonsWrapper: {
        flex: 1,
        paddingTop: "0px",
        paddingBottom: "0px",
        width: "100%"
    },
    body: {
        flex: 1,
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        width: "40%",
    },
  
    arrowWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px"
    },
    logo: {
        height: 80,
        width: 80,
        marginTop: "50px",
        marginBottom: "25px",
    },
   
    signupbutton: {
        border: "1px solid white",
        backgroundColor: "transparent",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700",
        borderRadius: "10px",
        textTransform: "none"
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700",
        marginBottom: "20px",
        marginTop: "60px",
        borderRadius: "10px",
        textTransform: "none"
    },
};
// Customizable Area End