import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tab:string;
  TermsAndConditions: string;  // To store Terms and Conditions data
  PrivacyPolicy: string;      
  termsCreatedAt: string;
  termsUpdatedAt: string;
  privacyCreatedAt: string;
  privacyUpdatedAt: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LegalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiTermsCallId: string = "";  // For API call ID (Terms)
  apiPrivacyCallId: string = "";  // For API call ID (Privacy)
  TermsAndConditions: string="";  // To store Terms and Conditions data
  PrivacyPolicy: string="";       // To store Privacy Policy data
  // To store Privacy Policy data
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceSuccessMessage) ,// To listen for API responses
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];
    this.state = {
    //   // Customizable Area Start
    tab: "one", 
      TermsAndConditions: "",  
      PrivacyPolicy: "",     
      termsCreatedAt: "",
      termsUpdatedAt: "",
      privacyCreatedAt: "",
      privacyUpdatedAt: "",
    //   // Customizable Area End
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getTermsAndConditions();  // Example: API call to get Terms and Conditions data
    // Customizable Area End
  }
  // Customizable Area Start
  // API Call to fetch Terms and Conditions
 getTermsAndConditions(): boolean {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiTermsCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.TermsAndConditions);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify({ "Content-Type": "application/json" }));

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
// API Call to fetch Privacy Policies (similar to the above function)
getPrivacyPolicies(): boolean {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiPrivacyCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.PrivacyPolicies);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify({ "Content-Type": "application/json" }));

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
    
  }

  // Receiving and handling the response
  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if (apiRequestCallId == this.apiTermsCallId) {
      const termsData = responseJson?.data[0]?.attributes?.description;
      const termsCreatedAt = responseJson?.data[0]?.attributes?.created_at;
      const termsUpdatedAt = responseJson?.data[0]?.attributes?.updated_at;
      this.setState({
          TermsAndConditions: termsData || [],
          termsCreatedAt,
          termsUpdatedAt,
        });
      
  }
  if (apiRequestCallId == this.apiPrivacyCallId) {
      const privacydata= responseJson.data[0]?.attributes?.description;
      const privacyCreatedAt = responseJson.data[0]?.attributes?.created_at;
      const privacyUpdatedAt = responseJson.data[0]?.attributes?.updated_at;
      this.setState({
          PrivacyPolicy: privacydata || [],
          privacyCreatedAt,
          privacyUpdatedAt,
        });
  
  }

}
  // Handling tab change
  handleChange = (event: any, newValue: string) => {
    this.setState({ tab: newValue }, () => {
      // Trigger API calls based on the selected tab
      if (this.state.tab === "one") {
        this.getTermsAndConditions();  // Call the API for Terms and Conditions
      } else if (this.state.tab === "two"  && !this.state.PrivacyPolicy) {
        this.getPrivacyPolicies();     // Call the API for Privacy Policies
      }
    });
  };
  // Customizable Area End
}
