Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.updateProfilePic = "account_block/update_profile_pic";
exports.updateProfile = "account_block/profile";
exports.getProfile = "account_block/logged_user";

exports.methodTypePut = "PUT";
exports.deleteAccount = "/account_block/delete_account";
exports.methodType = "DELETE";
exports.changePassUrl = "/bx_block_forgot_password/change_password";
exports.methodTypePatch = "PATCH";
exports.methodTypeGet = "GET"
exports.TermsAndConditions = "/bx_block_terms_and_conditions/terms_and_conditions";
exports.PrivacyPolicies="/bx_block_admin/privacy_policies"
// Customizable Area End